/** @jsx jsx */
import { jsx, Styled, Box } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import AccountLayout from '../account/AccountLayout';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Container from '../components/Container';
import * as analytics from '../utils/analytics';
import * as api from '../utils/api';
import getLanguage from '../utils/getLanguage';
import QRCode from 'react-qr-code';
import Spinner from '../components/Spinner';

const QrCodeLoginPage = ({ pageContext }) => {
  analytics.usePageCategory('qr-login');
  const language = useSelector(state => getLanguage(state));
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const [link, setLink] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    setLoading(true);
    setError(null);
    api
      .generateQrLoginLink(language)
      .then(({ link }) => setLink(link))
      .catch(setError)
      .finally(() => setLoading(false));
  }, [setLoading, setError, setLink, language]);

  return (
    <AccountLayout
      title={translate('appInstallPage.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container variant="parcel" sx={{ py: [3, 4] }}>
        <Styled.h1>{translate('appInstallPage.title')}</Styled.h1>
        <Styled.p>{translate('appInstallPage.readTheCode')}</Styled.p>
        {loading && (
          <Box sx={{ position: 'relative', height: 96 }}>
            <Spinner size="large" position="absolute" />
          </Box>
        )}
        {link && <QRCode sx={{ my: 2 }} value={link} />}
        {(error || (!loading && !link)) && (
          <Styled.p sx={{ color: 'danger' }}>
            {translate('appInstallPage.error')}
          </Styled.p>
        )}
        <Styled.p>{translate('appInstallPage.chooseInApp')}</Styled.p>
        <Styled.p>{translate('appInstallPage.ready')}</Styled.p>
      </Container>
    </AccountLayout>
  );
};

export default QrCodeLoginPage;
